














import { Component, Prop, Vue } from 'vue-property-decorator'
import Regular from '~components/content/post-types/Regular.vue'
import NoPicture from '~components/content/post-types/NoPicture.vue'
import { EnhancedPost, Term } from '~base/common/types'

@Component({
  components: {
    Regular,
    NoPicture,
  },
})
export default class Post extends Vue {
  @Prop({ type: Object, required: true }) readonly post!: EnhancedPost
  @Prop({ type: Number, required: true }) readonly index!: number

  get categories(): string {
    const [c]: Term[] = this.post.relevantCategories || []

    if (!c) return ``

    return `<span class='category-${c.slug}'>${c.name}</span>`
  }
}
