












import { Component, Prop, Vue } from 'vue-property-decorator'
import Post from '~components/content/Post.vue'
import { EnhancedPost } from '~base/common/types'

@Component({
  components: {
    Post,
  },
})
export default class Layout1 extends Vue {
  @Prop({ type: Array }) readonly posts!: EnhancedPost[]
  @Prop({ type: Number }) readonly layoutIndex!: number
}
