




























import { Component } from 'vue-property-decorator'
import PostType from '~components/content/post-types/PostType.vue'
import PostTitle from '~components/content/PostTitle.vue'
import LazyPicture from '~components/utils/LazyPicture.vue'

@Component({
  components: {
    PostTitle,
    LazyPicture,
  },
})
export default class Regular extends PostType {}
